/** @jsx jsx */
import React from 'react'
import { Styled, jsx } from "theme-ui"

const ExternalLink = ({children, ...props}) => (
  <Styled.a
    sx={{
      textDecoration: `none`,
    }}
    target="_blank"
    rel="noopener noreferrer"
    {...props}>
    {children ? children : props.href}
  </Styled.a>
)

export default ExternalLink