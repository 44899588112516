// src/gatsby-plugin-theme-ui/index.js
const black60 = `#767676`

export default {
    colors: {
        tertiary: black60
    },
    styles: {
        root: {
            lineHeight: 1.75
        }
    },
    sizes: {
        container: 1024,
    },
}